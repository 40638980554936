import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import IconArticle from "../utils/icons/Article Icon.svg"
import "../css/global.css"
const CollectionsCard = ({ collection }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allMarkdownRemark {
        edges {
          node {
            id
            parent {
              id
              ... on File {
                name
                sourceInstanceName
              }
            }
            frontmatter {
              title
              description
              date
            }
          }
        }
      }
    }
  `)

  const articles = data.allMarkdownRemark.edges
  const filtered_articles = articles.filter(
    item => item.node.parent.sourceInstanceName === collection.id
  )
  return (
    <Link
      key={collection.id}
      style={{
        boxShadow: `none`,
        "&:hover": {
          textDecoration: "none",
        },
      }}
      to={`/collections/${collection.id}`}
      state={{ targetFragment: "collection-div" }}
    >
      <div className=" z-30 shadow-xl hover:shadow-xs hover:bg-pale-blue bg-white border rounded-md border-color-border mt-4">
        <div className="p-10 flex flex-col">
          <p className=" sm:text-hxl text-base text-text-violet font-semibold capitalize">
            {collection.name}
          </p>
          <p className="mt-4 text-xs text-text-blue font-normal leading-tight ">
            {collection.description}
          </p>
          <ul className="mt-4 flex flex-row items-center">
            <img src={IconArticle} alt="Logo" />
            <p className="px-4 text-xs text-light-blue font-light leading-tight ">
              Consists {filtered_articles.length} articles
            </p>
          </ul>
        </div>
      </div>
    </Link>
  )
}

export default CollectionsCard
