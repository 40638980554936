import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import CollectionsCard from "../components/CollectionsCard"

export const pageQuery = graphql`
  fragment IndexArticleFragment on File {
    childMarkdownRemark {
      id
    }
  }
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    collections: allAdminYaml {
      edges {
        node {
          collections {
            id
            name
            slug
            folder
            description
          }
          id
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const collections = data.collections.edges[0].node.collections
  // console.log("collectionssssssssssssss" + JSON.stringify(data.collections.edges[0].node.collections))

  return (
    <Layout>
      <div className="bg-white w-full">
        <div className="container mt-16 md:max-w-4xl sm:max-w-sm md:px-8 lg:px-0 max-w-xs xs:px-4 sm:px-0 mx-auto ">
          {collections.map((node, index) => {
            return (
              <div key={index}>
                <CollectionsCard collection={node} />
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
